



































































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import CircleCard from '@/components/circleCard.vue';
import { StorageServices } from '@/services/StorageServices'
import CallToButton from '@/components/callToButton.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import PeriodiCard from '@/components/periodiCard.vue';
import DrawdownCard from '@/components/drawdownCard.vue';
import ChartCard from '@/components/chartCard.vue';
import PerformanceTable from '@/components/performanceTable.vue';
import MiniSpinner from '@/components/miniSpinner.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        CallToButton,
        SwiperSlides,
        PeriodiCard,
        DrawdownCard,
        ChartCard,
        PerformanceTable,
        MiniSpinner
    }
})
export default class MicroClassSummarySwitch extends Vue {

    benchmark: VM.BenchmarkVm = null;
    selectedPeriod: OM.DatiPeriodo = new OM.DatiPeriodo();
    scroller: any;
    performanceTable: any;
    currentSlide: number = 0;
    minVolatilita: number = 0;
    avgVolatilita: number = 0;
    maxVolatilita: number = 0;
    lastVolatilita: number = 0;

    created(){
        AssetClient.getBenchmarkByMacroAndMicro(this.$route.params.macroClass, this.$route.params.microClass, false)
        .then( data => {
            this.benchmark = data;
            this.selectedPeriod = this.benchmark.datiPeriodo[0];
            
            let volatilitaValues = this.benchmark.volatilita750.map(x => x.value).filter(x => x);
            this.minVolatilita = Math.min(...volatilitaValues);
            let total = 0;
            volatilitaValues.forEach(x => x ? total += x : false);
            this.avgVolatilita = total / volatilitaValues.length;
            this.maxVolatilita = Math.max(...volatilitaValues);
            this.lastVolatilita = volatilitaValues[volatilitaValues.length - 1];
        })
    }

    mounted(){
        this.scroller = document.querySelector('.playPageContainer');
        this.scroller.addEventListener('scroll', this.onPageScroll);
        this.performanceTable = document.querySelector('.performanceTable');
    }

    beforeRouteLeave(to, from, next){
        this.scroller.removeEventListener('scroll', this.onPageScroll);
        next();
    }
    
    openPerformanceTable(label){
        this.$router.push('/performanceHorizontalTable/' + label + '/' + this.benchmark.identifier);
    }

    onPageScroll(ev){
        if(this.currentSlide != 0)
            return;

        if(ev.target.scrollTop >=ev.target.scrollHeight - ev.target.offsetHeight - 20){
            this.performanceTable.classList.add('scrollit');
        } else {
            this.performanceTable.querySelector('tbody').scrollTop = 0;
            this.performanceTable.classList.remove('scrollit');
        }
    }

    setCurrentSlide(val){
        this.currentSlide = val;
    }

    seeAssets(){
        this.$router.push(this.$route.params.microClass + '/list')
    }

    openChart(data, label){
        StorageServices.setChartData(data);
        this.$router.push('/horizontalChart/' + label + '/' + this.$route.params.microClass + '/perc');
    }
    
    openTable(data, label){
        StorageServices.setHorizontalTableData(data);
        this.$router.push('/periodiHorizontalTable/' + label + '/' + this.$route.params.microClass);
    }

}

